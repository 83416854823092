import React, { useState, useRef, useCallback } from 'react';
import './Team.scss';
import { Link } from "react-router-dom";
import useDataSearch from '../../components/useInfiniteScroll/useInfiniteScroll'

function Team() {
  const [url, setUrl] = useState(`${process.env.REACT_APP_API_URL}/api/team/`)

  const {
    data,
    hasMore,
    loading,
    next
  } = useDataSearch(url)

  const observer = useRef()
  const lastDataElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setUrl(next)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore, next])

  return (
    <div className="Route_team">
      <section className="Section">
          <div className="Section_head">
            <h1>Team</h1>
            <div className="Slot_menu">
              <Link to="/team/create/image"><i className="material-icons">add</i>Create team</Link>
            </div>
          </div>
          <div className="Section_body">
            <div className="Shell">
              <div className="Slots">
                {data.map((team, index) => {
                  if (index + 1 === 20) {
                  return <Link ref={lastDataElementRef} to={"/team/edit/" + team.slug + "/image/"} key={team.slug} className="Slot">
                    <div className="Slot_image">
                      <img src={team.image} alt="item" />
                    </div>
                    <div className="Slot_desc">
                      <h5>{team.rank}</h5>
                      <h3>{team.name}</h3>
                    </div>
                  </Link>
                  }
                  else {
                    return <Link to={"/team/edit/" + team.slug + "/image/"} key={team.slug} className="Slot">
                      <div className="Slot_image">
                        <img src={team.image} alt="item" />
                      </div>
                      <div className="Slot_desc">
                        <h5>{team.rank}</h5>
                        <h3>{team.name}</h3>
                      </div>
                    </Link>
                  }
                })}
              </div>
            </div>
          </div>
      </section>
    </div>
  );
}

export default Team;

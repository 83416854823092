import React, { useState, useEffect, useRef } from 'react';
import { Route, Switch, NavLink, useRouteMatch, useParams } from "react-router-dom";
import './EditContent.scss'
import Cookies from 'js-cookie';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/plugins.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';
import FroalaEditor from 'react-froala-wysiwyg';

import placeholder from '../../assets/placeholder.jpg'

function CreateContent(props) {
  const [loading, setLoading] = useState(false);
  let { slug } = useParams();

  const config = {
        autoFocus: true,
        toolbartop: true,
        linkAlwaysBlank: true,
        fontSizeSelection: true,
        paragraphFormatSelection: true,
        htmlExecuteScripts: true,
        iframe: true,
        tabSpaces: 4,
        toolbarButtons: {
          // Key represents the more button from the toolbar.
          moreText: {
            // List of buttons used in the  group.
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

            // Alignment of the group in the toolbar.
            align: 'left',

            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
            buttonsVisible: 3
          },


          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
            align: 'left',
            buttonsVisible: 3
          },

          moreRich: {
            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
            align: 'left',
            buttonsVisible: 3
          },

          moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2
          }
        },
        imageUpload: true,
        events: {
          'image.beforeUpload': function(e) {
            // Before image is uploaded
            const data = new FormData();
            data.append('file', e[0], e[0].name);

            fetch("https://www.teamyotov.com/api/files/", {
             method: 'POST',
             headers: {'Authorization': ' Bearer ' + Cookies.get('token') },
             body: data
            })
            .then(res => res.json())
            .then(file => {
              this.image.insert(file['file'], true, null, this.image.get(), null);
            })
            .catch(err => {
              console.log(err);
            });
            return false;
          }
        }
      }

  const [image, setImage] = useState({ file: '', fileUrl: ''})
  const [data, setData] = useState({
    'slug': '',
    'title': '',
    'category': '',
    'body': '',
    'author': '',
    'service': '',
    'project': '',
    'technology': '',
    'faq': '',
    'blogpost': '',
    'image': image.fileUrl,
    'claps': '0'
  });
  const [ dataBg, setDataBg ] = useState({
    'title': '',
    'category': '',
    'body': '',
    'author': ''
  })
  const inputIm = useRef();
  let { url } = useRouteMatch();

  useEffect(() => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_URL}/api/contents/` + slug + '/', {headers: {"Accept-Language": "en"}})
      .then(res => res.json())
      .then(json => {
        if (json) {
          setData(json)
          setImage({'fileUrl': json.image})
        } else {
          setData([])
        }
      })

    fetch(`${process.env.REACT_APP_API_URL}/api/contents/` + slug + '/', {headers: {"Accept-Language": "bg"}})
      .then(res => res.json())
      .then(json => {
        if (json) {
          setDataBg(json)
        } else {
          setDataBg([])
        }
       })
    setLoading(false)
  }, [slug])

  const handleBgEditorChange = (body) => {
    setDataBg({...dataBg, 'body': body})
  }

  const handleEditorChange = (body) => {
    setData({...data, 'body': body})
  }

  const handleChange = (event) => {
    setData({...data, [event.target.name]: event.target.value})
  }

  const handleBgChange = (event) => {
    setDataBg({...dataBg, [event.target.name]: event.target.value})
  }

  if (image.fileUrl === '') {
    image.fileUrl = placeholder
  }

  const uploadImage = () => {
    let file = inputIm.current.files[0]

    if (file !== undefined) {
      if (file.size > 500000) {
        alert('File is too big');
      }
      else {
        setImage({
          'fileUrl': URL.createObjectURL(file),
          'file': file
        })
        setData({...data, [inputIm.current.name]: URL.createObjectURL(file)})
      }
    }
  }

  const save = () => {
    let formdata = new FormData();

    formdata.append("slug", data["slug"]);
    formdata.append("title", data["title"]);
    formdata.append("category", data["category"]);
    formdata.append("body", data["body"]);
    formdata.append("author", data["author"]);
    formdata.append("claps", data["claps"]);
    if (data["service"] !== "None") {
      formdata.append("service", data["service"]);
    }
    if (data["project"] !== "None") {
      formdata.append("project", data["project"]);
    }
    if (data["technology"] !== "None") {
      formdata.append("technology", data["technology"]);
    }
    if (data["faq"] !== "None") {
      formdata.append("faq", data["faq"]);
    }
    if (data["blogpost"] !== "None") {
      formdata.append("blogpost", data["blogpost"]);
    }

    if (image.file) {
      formdata.append("image", image.file, image.file.name);
    }

    let formdataBg = new FormData()
    formdataBg.append("title", dataBg["title"]);
    formdataBg.append("category", dataBg["category"]);
    formdataBg.append("body", dataBg["body"]);
    formdataBg.append("author", dataBg["author"]);

    url = `${process.env.REACT_APP_API_URL}/api/contents/` + data["slug"] + "/"
    fetch(url, {
     method: 'PATCH',
     headers: {"Accept-Language": "en", 'Authorization': ' Bearer ' + Cookies.get('token') },
     body: formdata
    })
    .then(response => console.log(response.text()))
    .then(function(result) {
      fetch(url, {
       method: 'PATCH',
       headers: {"Accept-Language": "bg", 'Authorization': ' Bearer ' + Cookies.get('token') },
       body: formdataBg
      })
      .then(response => console.log(response.text()))
    })
    .catch(error => console.log('error', error))

  }

  const deleteContent = () => {
    url = `${process.env.REACT_APP_API_URL}/api/contents/` + slug + '/'
    fetch(url, {
     method: 'DELETE',
     headers: {'Authorization': ' Bearer ' + Cookies.get('token') },
    })
    .then(response => response.text())
    .catch(error => console.log('error', error))

  }

  return (
    <div className="Route_edit">
      <section className="Section">
        <div className="Section_head">
          <h1>Edit Content</h1>
        </div>
        <div className="Section_body">
          <div className="Shell">
            { loading ? <div>Loading</div> :
              <div className="Item">
                <div className="Item_menu">
                  <ul>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/image`}>Image</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/details`}>Details</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/related`}>Related</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/body`}>Body</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/bodybg`}>BodyBg</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="Item_inner">
                  <Switch>
                    <Route exact path={`${url}/image`}>
                      <div className="Upload">
                        <div className="Upload_wrapper" onClick={() => {inputIm.current.click()}}>
                          <div className="Upload_desc">
                            <i className="material-icons">cloud_upload</i>
                            <h5>Upload image</h5>
                            <p>File size must be less than 500kb</p>
                          </div>
                          <div className="Upload_image" style={{ backgroundImage: 'url('+image.fileUrl+')' }}></div>
                          <input name="image" ref={inputIm} onChange={uploadImage} id="image-upload" type="file" accept="image/jpeg, image/png" />
                        </div>
                        <div className="Upload_details">
                          <div className="Item_field">
                            <input readOnly type="text" value={image.fileUrl.slice(image.fileUrl.lastIndexOf('/') + 1)} />
                            <h5>Name</h5>
                          </div>
                          <div className="Item_field">
                            <input readOnly type="text" value={image.fileUrl} />
                            <h5>Url</h5>
                          </div>
                        </div>
                      </div>
                    </Route>
                    <Route path={`${url}/related`}>
                      <div className="Item_field">
                        <input onChange={handleChange} name="service" type="text" value={data.service} />
                        <h5>Service</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleChange} name="project" type="text" value={data.project} />
                        <h5>Project</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleChange} name="technology" type="text" value={data.technology} />
                        <h5>Technology</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleChange} name="faq" type="text" value={data.faq} />
                        <h5>Faq</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleChange} name="blogpost" type="text" value={data.blogpost} />
                        <h5>Blogpost</h5>
                      </div>
                    </Route>
                    <Route path={`${url}/details`}>
                      <div className="Details">
                        <div className="Item_field">
                          <input onChange={handleChange} name="slug" type="text" value={data.slug} />
                          <h5>Slug</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleChange} name="title" type="text" value={data.title} />
                          <h5>Title</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleBgChange} name="title" type="text" value={dataBg.title} />
                          <h5>Title-Bg</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleChange} name="category" type="text" value={data.category} />
                          <h5>Category</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleBgChange} name="category" type="text" value={dataBg.category} />
                          <h5>Category-Bg</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleChange} name="author" type="text" value={data.author || ''} />
                          <h5>Author</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleBgChange} name="author" type="text" value={dataBg.author} />
                          <h5>Author-Bg</h5>
                        </div>
                        <div className="Item_field">
                          <input readOnly onChange={handleChange} name="claps" type="number" value={0} />
                          <h5>Claps</h5>
                        </div>
                      </div>
                    </Route>
                    <Route path={`${url}/body`}>
                      <div className="Single_head">
                        <h5>{data.category}</h5>
                        <h2>{data.title}</h2>
                        <div className="Single_image" style={{ backgroundImage: 'url('+image.fileUrl+')' }}></div>
                        <div className="Single_author">
                          <div className="Single_author_content">
                            <h3>{data.author}</h3>
                            <p>{data.published}</p>
                          </div>
                        </div>
                      </div>
                      <div id="all" className="Single_body" dangerouslySetInnerHTML={{ __html: data.body }}>

                      </div>
                      <FroalaEditor
                        tag='textarea'
                        config={config}
                        model={data.body}
                        onModelChange={handleEditorChange}
                      />
                    </Route>
                    <Route path={`${url}/bodybg`}>
                      <div className="Single_head">
                        <h5>{dataBg.category}</h5>
                        <h2>{dataBg.title}</h2>
                        <div className="Single_image" style={{ backgroundImage: 'url('+image.fileUrl+')' }}></div>
                        <div className="Single_author">
                          <div className="Single_author_content">
                            <h3>{dataBg.author}</h3>
                            <p>{data.published}</p>
                          </div>
                        </div>
                      </div>
                      <div id="alll" className="Single_body" dangerouslySetInnerHTML={{ __html: dataBg.body }}>

                      </div>
                      <FroalaEditor
                        tag='textarea'
                        config={config}
                        model={dataBg.body}
                        onModelChange={handleBgEditorChange}
                      />
                    </Route>
                  </Switch>
                </div>
                <div className="Item_actions">
                  <button onClick={save}><i className="material-icons">save</i>Save</button>
                  <button onClick={deleteContent}><i className="material-icons">delete</i>Delete</button>
                </div>
              </div>
              }
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateContent;

import React from 'react';
import './PreviewFaq.scss';

export default function PreviewFaq({ data, dataBg }) {
  return(
    <div className="Differences">
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/faqs/"} className="Difference">
        <div className="Difference_image" style={{ backgroundImage: 'url('+data.image+')' }}></div>
        <div className="Difference_content">
          <h5>{data.category}</h5>
          <h3>{data.title}</h3>
          <p>{data.description}</p>
        </div>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/faqs/"} className="Difference">
        <div className="Difference_image" style={{ backgroundImage: 'url('+data.image+')' }}></div>
        <div className="Difference_content">
          <h5>{dataBg.category}</h5>
          <h3>{dataBg.title}</h3>
          <p>{dataBg.description}</p>
        </div>
      </a>
    </div>
  );
}

import React from 'react';
import './PreviewBlogpost.scss';

export default function PreviewBlogpost({ data, dataBg }) {
  return(
    <div className="Blog">
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/blog/"} className="Blogpost">
        <div className="Blogpost_content">
          <p>{data.category}</p>
          <h4>{data.title}</h4>
        </div>
        <div className="Blogpost_wrap">
          <div className="Blogpost_image" style={{ backgroundImage: 'url('+data.image+')' }}></div>
        </div>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/blog/"} className="Blogpost">
        <div className="Blogpost_content">
          <p>{dataBg.category}</p>
          <h4>{dataBg.title}</h4>
        </div>
        <div className="Blogpost_wrap">
          <div className="Blogpost_image" style={{ backgroundImage: 'url('+data.image+')' }}></div>
        </div>
      </a>
    </div>
  );
}

import React, { useState, useRef, useCallback } from 'react';
import './Contents.scss';
import { Link } from "react-router-dom";
import useDataSearch from '../../components/useInfiniteScroll/useInfiniteScroll'

function Contents() {
  const [url, setUrl] = useState(`${process.env.REACT_APP_API_URL}/api/contents/`)

  const {
    data,
    hasMore,
    loading,
    next
  } = useDataSearch(url)

  const observer = useRef()
  const lastDataElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setUrl(next)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore, next])

  return (
    <div className="Route_contents">
      <section className="Section">
          <div className="Section_head">
            <h1>Content</h1>
            <div className="Slot_menu">
              <Link to="/contents/create/image"><i className="material-icons">add</i>Create content</Link>
            </div>
          </div>
          <div className="Section_body">
            <div className="Shell">
              <div className="Slots">
                {data.map((content, index) => {
                  if (index + 1 === 20) {
                  return <Link ref={lastDataElementRef} to={"/contents/edit/" + content.slug + "/image/"} key={content.slug} className="Slot">
                    <div className="Slot_image">
                      <img src={content.image} alt="item" />
                    </div>
                    <div className="Slot_desc">
                      <h5>{content.category}</h5>
                      <h3>{content.title}</h3>
                    </div>
                  </Link>
                  }
                  else {
                    return <Link to={"/contents/edit/" + content.slug + "/image/"} key={content.slug} className="Slot">
                      <div className="Slot_image">
                        <img src={content.image} alt="item" />
                      </div>
                      <div className="Slot_desc">
                        <h5>{content.category}</h5>
                        <h3>{content.title}</h3>
                      </div>
                    </Link>
                  }
                })}
              </div>
            </div>
          </div>
      </section>
    </div>
  );
}

export default Contents;

import React, { useState } from 'react';
import './Login.scss';
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";

function Login() {
  let history = useHistory();

  const [login, setLogin] = useState(
      { username: '', password: ''}
  );
  const [errLogin, setErrLogin] = useState(
    { username: '', password: ''}
  );

  const handleChange = (event) => {
      setLogin({...login, [event.target.name]: event.target.value})
      setErrLogin({...errLogin, [event.target.name]: ""})
  }

  const handleBlur = (event) => {
    if (event.target.value !== "" || event.target.parentNode.children[2].innerHTML !== "") {
      event.target.parentNode.children[1].style = "color: #9d9d9d; font-size: 16px; transform: translateY(calc(-50% - 36px));"
    }
    else {
      event.target.parentNode.children[1].style = "color: #757575; font-size: 24px; transform: translateY(calc(-50%));"
    }
  }

  const handleFocus = (event) => {
    event.target.parentNode.children[1].style = "color: #9d9d9d; font-size: 16px; transform: translateY(calc(-50% - 36px));"
  }

  const handleSubmit = (event) => {
      event.preventDefault()
      fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/create`, {
       method: 'post',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify(login)
      })
        .then(function (response) {
          return response.json();
        })
        .then(function(data) {
          Cookies.set('token', data['access']);
          Cookies.set('refresh', data['refresh']);
          history.go('/dashboard')
        })
  }

  return (
    <div className="Route_login">
      <a className="Logo" href="/">TeamYotov</a>
      <div className="Login">
        <div className="Login_head">
          <h1>Login</h1>
          <div className="Login_decor">
            <i className="material-icons">fingerprint</i>
          </div>
        </div>
        <form className="Form">
          <div className="Form_row">
            <input id="username" type="text" name="username" onFocus={handleFocus} onBlur={handleBlur} value={login.username} onChange={handleChange} required />
            <label htmlFor="username">Username</label>
            <p className="error">{errLogin['username']}</p>
            <div className="bar"></div>
          </div>
          <div className="Form_row">
            <input id="password" type="password" name="password" onFocus={handleFocus} onBlur={handleBlur} value={login.password} onChange={handleChange} required />
            <label htmlFor="password">Password</label>
            <p className="error">{errLogin['password']}</p>
            <div className="bar"></div>
          </div>
          <div className="Form_actions">
            <button onClick={handleSubmit} type="submit">Login</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;

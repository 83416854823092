import React, { useState, useRef, useCallback } from 'react';
import './Technology.scss';
import { Link } from "react-router-dom";
import useDataSearch from '../../components/useInfiniteScroll/useInfiniteScroll'

function Technology() {
  const [url, setUrl] = useState(`${process.env.REACT_APP_API_URL}/api/technology/`)

  const {
    data,
    hasMore,
    loading,
    next
  } = useDataSearch(url)

  const observer = useRef()
  const lastDataElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setUrl(next)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore, next])

  return (
    <div className="Route_technology">
      <section className="Section">
          <div className="Section_head">
            <h1>Technology</h1>
            <div className="Slot_menu">
              <Link to="/create/technology/image"><i className="material-icons">add</i>Create technology</Link>
            </div>
          </div>
          <div className="Section_body">
            <div className="Shell">
              <div className="Slots">
                  {data.map((technology, index) => {
                    if (index + 1 === 20) {
                      return <Link ref={lastDataElementRef} to={"/edit/technology/" + technology.slug + "/image/"} key={technology.slug} className="Slot">
                        {technology.video === "" ?
                        <div className="Slot_image">
                          <img src={technology.image} alt="item" />
                        </div> :
                        <div className="Slot_video">
                          <video muted autoPlay loop playsInline>
                            <source src={technology.video} type="video/mp4" />
                          </video>
                        </div>}
                        <div className="Slot_desc">
                        <h5>{technology.category}</h5>
                          <h3>{technology.title}</h3>
                        </div>
                      </Link>
                    } else {
                      return <Link to={"/edit/technology/" + technology.slug + "/image/"} key={technology.slug} className="Slot">
                        {technology.video === "" ?
                        <div className="Slot_image">
                          <img src={technology.image} alt="item" />
                        </div> :
                        <div className="Slot_video">
                          <video muted autoPlay loop playsInline>
                            <source src={technology.video} type="video/mp4" />
                          </video>
                        </div>}
                        <div className="Slot_desc">
                        <h5>{technology.category}</h5>
                          <h3>{technology.title}</h3>
                        </div>
                      </Link>
                    }
                  })}
              </div>
            </div>
          </div>
      </section>
    </div>
  );
}

export default Technology;

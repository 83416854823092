import React, { useState, useEffect, useRef } from 'react';
import './Item.scss';
import { Route, Switch, NavLink, Link, useRouteMatch, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import PreviewService from '../../components/previewService/PreviewService'
import PreviewProject from '../../components/previewProject/PreviewProject'
import PreviewFaq from '../../components/previewFaq/PreviewFaq'
import PreviewBlogpost from '../../components/previewBlogpost/PreviewBlogpost'
import PreviewTechnology from '../../components/previewTechnology/PreviewTechnology'
import placeholder from '../../assets/placeholder.jpg'
import placeholderVid from '../../assets/placeholder.mp4'

function Item(props) {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState({ file: '', fileUrl: ''})
  const [video, setVideo] = useState({ file: '', fileUrl: ''})
  const inputIm = useRef();
  const inputVid = useRef();
  let { url } = useRouteMatch();

  const [data, setData] = useState({
    'slug': '',
    'title': '',
    'description': '',
    'category': '',
    'webpage': '',
    'position': '',
    'image': image.fileUrl,
    'video': video.fileUrl
  });
  const [ dataBg, setDataBg ] = useState({
    'title': '',
    'description': '',
    'category': ''
  })
  let { section } = useParams();

  useEffect(() => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_URL}/api` + url.replace('edit/', '') + "/", {headers: {"Accept-Language": "en"}})
      .then(res => res.json())
      .then(json => {
        if (json) {
          setData(json)
          setImage({'fileUrl': json.image})
          setVideo({'fileUrl': json.video})
        } else {
          setData([])
        }
      })

    fetch(`${process.env.REACT_APP_API_URL}/api` + url.replace('edit/', '') + "/", {headers: {"Accept-Language": "bg"}})
      .then(res => res.json())
      .then(json => {
        if (json) {
          setDataBg(json)
        } else {
          setDataBg([])
        }
       })
    setLoading(false)
  }, [url])

  if (image.fileUrl === '') {
    image.fileUrl = placeholder
  }
  if (video.fileUrl === '') {
    video.fileUrl = placeholderVid
  }

  const handleChange = (event) => {
      setData({...data, [event.target.name]: event.target.value})
  }

  const handleBgChange = (event) => {
      setDataBg({...dataBg, [event.target.name]: event.target.value})
  }

  function uploadImage() {
    let file = inputIm.current.files[0]

    if (file !== undefined) {
      if (file.size > 500000) {
        alert('File is too big');
      }
      else {
        setImage({
          'fileUrl': URL.createObjectURL(file),
          'file': file
        })
      }
    }
  }

  function uploadVideo() {
    let file = inputVid.current.files[0]

    if (file !== undefined) {
      if (file.size > 1000000) {
        alert('File is too big');
      }
      else {
        setVideo({
          'fileUrl': URL.createObjectURL(file),
          'file': file
        })
      }
    }
  }

  const save = () => {
    let formdata = new FormData();
    if (!video.fileUrl.includes("placeholder") && video.file) {
      formdata.append("video", video.file, video.file.name);
    }
    if (image.file) {
      formdata.append("image", image.file, image.file.name);
    }

    formdata.append("title", data["title"]);
    formdata.append("description", data["description"]);
    formdata.append("category", data["category"]);
    formdata.append("webpage", data["webpage"]);
    formdata.append("position", data["position"]);

    let formdataBg = new FormData()
    formdataBg.append("title", dataBg["title"]);
    formdataBg.append("description", dataBg["description"]);
    formdataBg.append("category", dataBg["category"]);

    url = `${process.env.REACT_APP_API_URL}/api/` + section.replace('edit/', '') + '/' + data['slug'] + '/'
    fetch(url, {
     method: 'PATCH',
     headers: {"Accept-Language": "en", 'Authorization': ' Bearer ' + Cookies.get('token') },
     body: formdata
    })
    .then(response => response.text())
    .then(function(result) {
      if (result !== undefined) {
        fetch(url, {
         method: 'PATCH',
         headers: {"Accept-Language": "bg", 'Authorization': ' Bearer ' + Cookies.get('token') },
         body: formdataBg
       }).then(response => console.log(response.text()))
      }
    })
    .catch(error => console.log('error', error))
  }

  const deleteItem = () => {
    url = `${process.env.REACT_APP_API_URL}/api/` + section.replace('edit/', '') + '/' + data['slug'] + '/'
    fetch(url, {
     method: 'DELETE',
     headers: {'Authorization': ' Bearer ' + Cookies.get('token') },
    })
    .then(response => response.text())
    .catch(error => console.log('error', error))

  }

  return (
    <div className="Route_edit">
      <section className="Section">
          <div className="Section_head">
            <h1>Edit {section}</h1>
            <div className="Slot_menu">
              <Link to="/create/services/image"><i className="material-icons">add</i>Create {section}</Link>
            </div>
          </div>
          <div className="Section_body">
            <div className="Shell">
            {loading ? <div>Loading</div> :
              <div key={data.slug} className="Item">
                <div className="Item_menu">
                  <ul>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/image`}>Image</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/video`}>Video</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/details`}>Details</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/place`}>Place</NavLink>
                    </li>
                    <li>
                      <NavLink activeClassName="chosen" to={`${url}/preview`}>Preview</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="Item_inner">
                  <Switch>
                    <Route exact path={`${url}/image`}>
                      <div className="Upload">
                        <div className="Upload_wrapper" onClick={() => {inputIm.current.click()}}>
                          <div className="Upload_desc">
                            <i className="material-icons">cloud_upload</i>
                            <h5>Upload image</h5>
                            <p>File size must be less than 500kb</p>
                          </div>
                          <div className="Upload_image" style={{ backgroundImage: 'url('+image.fileUrl+')' }}></div>
                          <input ref={inputIm} onChange={uploadImage} id="image-upload" type="file" accept="image/jpeg, image/png" />
                        </div>
                        <div className="Upload_details">
                          <div className="Item_field">
                            <input readOnly type="text" value={image.fileUrl.slice(image.fileUrl.lastIndexOf('/') + 1)} />
                            <h5>Name</h5>
                          </div>
                          <div className="Item_field">
                            <input readOnly type="text" value={image.fileUrl} />
                            <h5>Url</h5>
                          </div>
                        </div>
                      </div>
                    </Route>
                    <Route path={`${url}/video`}>
                    <div className="Upload">
                      <div className="Upload_wrapper" onClick={() => {inputVid.current.click()}}>
                        <div className="Upload_desc">
                          <i className="material-icons">cloud_upload</i>
                          <h5>Upload Video</h5>
                          <p>File size must be less than 1mb</p>
                        </div>
                        <div className="Upload_video">
                          <video muted autoPlay loop playsInline>
                            <source src={video.fileUrl} type="video/mp4" />
                          </video>
                        </div>
                        <input ref={inputVid} onChange={uploadVideo} id="video-upload" type="file" accept="video/mp4" />
                      </div>
                      <div className="Upload_details">
                        <div className="Item_field">
                          <input readOnly type="text" value={video.fileUrl.slice(video.fileUrl.lastIndexOf('/') + 1)} />
                          <h5>Name</h5>
                        </div>
                        <div className="Item_field">
                          <input readOnly type="text" value={video.fileUrl} />
                          <h5>Url</h5>
                        </div>
                      </div>
                    </div>
                    </Route>
                    <Route path={`${url}/details`}>
                      <div className="Details">
                        <div className="Item_field">
                          <input type="text" value={data.slug} readOnly />
                          <h5>Slug</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleChange} name="title" type="text" value={data.title} />
                          <h5>Title</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleBgChange} name="title" type="text" value={dataBg.title || ''} />
                          <h5>Title-Bg</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleChange} name="description" type="text" value={data.description} />
                          <h5>Description</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleBgChange} name="description" type="text" value={dataBg.description || ''} />
                          <h5>Description-Bg</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleChange} name="category" type="text" value={data.category} />
                          <h5>Category</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleBgChange} name="category" type="text" value={dataBg.category || ''} />
                          <h5>Category-Bg</h5>
                        </div>
                      </div>
                    </Route>
                    <Route path={`${url}/place`}>
                      <div className="Place">
                        <div className="Item_field">
                          <input onChange={handleChange} name="webpage" type="text" value={data.webpage} />
                          <h5>Page</h5>
                        </div>
                        <div className="Item_field">
                          <input onChange={handleChange} name="position" type="number" value={data.position} />
                          <h5>Position</h5>
                        </div>
                      </div>
                    </Route>
                    <Route path={`${url}/preview`}>
                      {url.includes("services") ?
                        <PreviewService data={data} dataBg={dataBg} /> : ""
                      }
                      {url.includes("projects") ?
                        <PreviewProject data={data} dataBg={dataBg} /> : ""
                      }
                      {url.includes("faqs") ?
                        <PreviewFaq data={data} dataBg={dataBg} /> : ""
                      }
                      {url.includes("blogposts") ?
                        <PreviewBlogpost data={data} dataBg={dataBg} /> : ""
                      }
                      {url.includes("technology") ?
                        <PreviewTechnology data={data} dataBg={dataBg} /> : ""
                      }
                    </Route>
                  </Switch>
                </div>
                <div className="Item_actions">
                  <button onClick={save}><i className="material-icons">save</i>Save</button>
                  <button onClick={deleteItem}><i className="material-icons">delete</i>Delete</button>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
}

export default Item;

import React, { useState, useEffect } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Link,
  Redirect
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import ScrollToTop from 'react-router-scroll-top'
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

import Dashboard from './routes/dashboard/Dashboard'
import Blogposts from './routes/blogposts/Blogposts'
import Contents from './routes/contents/Contents'
import Faqs from './routes/faqs/Faqs'
import Login from './routes/login/Login'
import Projects from './routes/projects/Projects'
import Services from './routes/services/Services'
import Technology from './routes/technology/Technology'
import Item from './routes/item/Item'
import Team from './routes/team/Team'
import Create from './routes/create/Create'
import CreateTeam from './routes/createTeam/CreateTeam'
import EditTeam from './routes/editTeam/EditTeam'
import CreateContent from './routes/createContent/CreateContent'
import EditContent from './routes/editContent/EditContent'


const ProtectedRoute = ({ isAllowed, ...props }) =>
     isAllowed
     ? <Route {...props}/>
   : <Redirect to="/"/>;

 const LoginRoute = ({ isAllowed, ...props }) =>
    isAllowed
    ? <Redirect to="/dashboard"/>
  : <Route {...props}/>;

function App() {
  const history = createBrowserHistory();
  const [ nav, setNav ] = useState(false)
  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  let token = Cookies.get('token')
  let refresh = Cookies.get('refresh')

  useEffect(() => {
    if (token && jwt.decode(token)) {
      const expiry = jwt.decode(token).exp;
      const now = new Date();
      if (now.getTime() < expiry * 1000) {
        setIsLoggedIn(true);
      }
      else {
        fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh`, {
         method: 'post',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify({'refresh': refresh})
        })
          .then(function (response) {
            return response.json();
          })
          .then(function(data) {
            if (data['access'] !== undefined) {
              Cookies.set('token', data.access);
              setIsLoggedIn(true);
            }
            else {
              setIsLoggedIn(false);
            }
          })
      }
    }
    else {
      setIsLoggedIn(false);
    }
  }, [token, refresh, history])

  useEffect(() => {
    if (isLoggedIn === false) {
      history.push('/')
    }
  })

  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('refresh')
    history.go('/')
  }

  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <header className={`Header ${isLoggedIn ? "display" : ""}`}>
            <div className="Header_inner">
              <a className="Logo" href="/">TeamYotov</a>

              <div id="Hamburger" className={ nav ? "cross" : "" } onClick={() => setNav(!nav) } >
                <span></span>
                <span></span>
                <span></span>
              </div>

              <nav className="Nav">
                <ul>
                  <li>
                    <NavLink activeClassName="selected" to="/dashboard/">
                      <i className="material-icons">dashboard</i>
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="selected" to="/services/">
                      <i className="material-icons">business</i>
                      Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="selected" to="/projects/">
                      <i className="material-icons">local_activity</i>
                      Projects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="selected" to="/Faqs/">
                      <i className="material-icons">question_answer</i>
                      Faqs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="selected" to="/technology/">
                      <i className="material-icons">memory</i>
                      Technology
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="selected" to="/blogposts/">
                      <i className="material-icons">local_library</i>
                      Blogposts
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="selected" to="/team/">
                      <i className="material-icons">group</i>
                      Team
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="selected" to="/contents/">
                      <i className="material-icons">view_quilt</i>
                      Contents
                    </NavLink>
                  </li>
                  <li>
                    <Link onClick={logout} to="/login">
                      <i className="material-icons">logout</i>
                      Logout
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </header>

          <Switch>
            <LoginRoute
               isAllowed={isLoggedIn}
               exact
               path="/"
               component={Login}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               exact
               path="/dashboard"
               component={Dashboard}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               exact
               path="/services"
               component={Services}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               path="/edit/:section/:slug/"
               component={Item}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               exact
               path="/projects"
               component={Projects}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               exact
               path="/technology"
               component={Technology}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               exact
               path="/blogposts"
               component={Blogposts}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               exact
               path="/contents"
               component={Contents}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               path="/contents/create/"
               component={CreateContent}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               path="/contents/edit/:slug"
               component={EditContent}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               exact
               path="/faqs"
               component={Faqs}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               exact
               path="/team"
               component={Team}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               path="/team/create/"
               component={CreateTeam}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               path="/team/edit/:slug"
               component={EditTeam}/>
            <ProtectedRoute
               isAllowed={isLoggedIn}
               path="/create/:section/"
               component={Create}/>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;

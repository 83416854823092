import React from 'react';
import './PreviewService.scss';

export default function PreviewService({ data, dataBg }) {

  return(
    <div className="Preview_services">
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/services/"} className="Service">
        <div className="Service_content">
          <h5>{data.category}</h5>
          <p>{data.title}</p>
        </div>
        <div className="Service_image" style={{ backgroundImage: 'url('+data.image+')' }}>

        </div>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/services/"} className="Service">
        <div className="Service_content">
          <h5>{dataBg.category}</h5>
          <p>{dataBg.title}</p>
        </div>
        <div className="Service_image" style={{ backgroundImage: 'url('+data.image+')' }}>

        </div>
      </a>
    </div>
  );
}

import React from 'react';
import './PreviewTeam.scss';

export default function PreviewTeam({ data, dataBg }) {

  return(
    <div className="Preview_team">
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/team/"} className="Member">
        <div className="Member_image" style={{ backgroundImage: 'url('+data.image+')' }}>

        </div>
        <div className="Member_content">
          <h5>{data.rank}</h5>
          <h3>{data.name}</h3>
          <p>{data.description}</p>
        </div>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/team/"} className="Member">
        <div className="Member_image" style={{ backgroundImage: 'url('+data.image+')' }}>

        </div>
        <div className="Member_content">
          <h5>{dataBg.rank}</h5>
          <h3>{dataBg.name}</h3>
          <p>{dataBg.description}</p>
        </div>
      </a>
    </div>
  );
}

import React from 'react';
import './PreviewTechnology.scss';

export default function PreviewTechnology({ data, dataBg }) {

  return(
    <div className="Technologies">
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/technology/"} className="Technology">
        <div className="Technology_content">
          <div className="Technology_content_head">
            <h4>{data.title}</h4>
          </div>
          <div className="Technology_content_body">
            <p>{data.description}</p>
          </div>
        </div>
        <div className="Technology_image_wrapper">
          <div className="Technology_image" style={{ backgroundImage: 'url('+data.image+')' }}>

          </div>
        </div>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/technology/"} className="Technology">
        <div className="Technology_content">
          <div className="Technology_content_head">
            <h4>{dataBg.title}</h4>
          </div>
          <div className="Technology_content_body">
            <p>{dataBg.description}</p>
          </div>
        </div>
        <div className="Technology_image_wrapper">
          <div className="Technology_image" style={{ backgroundImage: 'url('+data.image+')' }}>

          </div>
        </div>
      </a>
    </div>
  );
}

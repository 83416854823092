import React, { useState, useRef } from 'react';
import { Route, Switch, NavLink, useRouteMatch, useParams } from "react-router-dom";
import './CreateTeam.scss'
import Cookies from 'js-cookie';
import placeholder from '../../assets/placeholder.jpg'

function CreateTeam(props) {
  const [image, setImage] = useState({ file: '', fileUrl: ''})
  const [data, setData] = useState({
    'slug': '',
    'name': '',
    'description': '',
    'rank': '',
    'linkedin': '',
    'image': image.fileUrl
  });
  const [ dataBg, setDataBg ] = useState({
    'name': '',
    'description': '',
    'rank': ''
  })
  const inputIm = useRef();
  let { section } = useParams();
  let { url } = useRouteMatch();

  const handleChange = (event) => {
      setData({...data, [event.target.name]: event.target.value})
  }

  const handleBgChange = (event) => {
      setDataBg({...dataBg, [event.target.name]: event.target.value})
  }

  if (image.fileUrl === '') {
    image.fileUrl = placeholder
  }

  const uploadImage = () => {
    let file = inputIm.current.files[0]

    if (file !== undefined) {
      if (file.size > 500000) {
        alert('File is too big');
      }
      else {
        setImage({
          'fileUrl': URL.createObjectURL(file),
          'file': file
        })
        setData({...data, [inputIm.current.name]: URL.createObjectURL(file)})
      }
    }
  }

  const save = () => {
    let formdata = new FormData();

    formdata.append("slug", data["slug"]);
    formdata.append("name", data["name"]);
    formdata.append("description", data["description"]);
    formdata.append("rank", data["rank"]);
    formdata.append("linkedin", data["linkedin"]);
    formdata.append("image", image.file, image.file.name);

    let formdataBg = new FormData()
    formdata.append("name", data["name"]);
    formdata.append("description", data["description"]);
    formdata.append("rank", data["rank"]);

    url = `${process.env.REACT_APP_API_URL}/api/team/`
    fetch(url, {
     method: 'POST',
     headers: {"Accept-Language": "en", 'Authorization': ' Bearer ' + Cookies.get('token') },
     body: formdata
    })
    .then(response => response.text())
    .then(function(result) {
      if (result !== undefined) {
        fetch(url + data["slug"] + "/", {
         method: 'PATCH',
         headers: {"Accept-Language": "bg", 'Authorization': ' Bearer ' + Cookies.get('token') },
         body: formdataBg
        })
      }
    })
    .then(response => response.text())
    .catch(error => console.log('error', error))
  }

  return (
    <div className="Route_create">
      <section className="Section">
        <div className="Section_head">
          <h1>Create Member</h1>
        </div>
        <div className="Section_body">
          <div className="Shell">
            <div className="Item">
              <div className="Item_menu">
                <ul>
                  <li>
                    <NavLink activeClassName="chosen" to={`${url}/image`}>Image</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="chosen" to={`${url}/details`}>Details</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="chosen" to={`${url}/preview`}>Preview</NavLink>
                  </li>
                </ul>
              </div>
              <div className="Item_inner">
                <Switch>
                  <Route exact path={`${url}/image`}>
                    <div className="Upload">
                      <div className="Upload_wrapper" onClick={() => {inputIm.current.click()}}>
                        <div className="Upload_desc">
                          <i className="material-icons">cloud_upload</i>
                          <h5>Upload image</h5>
                          <p>File size must be less than 500kb</p>
                        </div>
                        <div className="Upload_image" style={{ backgroundImage: 'url('+image.fileUrl+')' }}></div>
                        <input name="image" ref={inputIm} onChange={uploadImage} id="image-upload" type="file" accept="image/jpeg, image/png" />
                      </div>
                      <div className="Upload_details">
                        <div className="Item_field">
                          <input readOnly type="text" value={image.fileUrl.slice(image.fileUrl.lastIndexOf('/') + 1)} />
                          <h5>Name</h5>
                        </div>
                        <div className="Item_field">
                          <input readOnly type="text" value={image.fileUrl} />
                          <h5>Url</h5>
                        </div>
                      </div>
                    </div>
                  </Route>
                  <Route path={`${url}/details`}>
                    <div className="Details">
                      <div className="Item_field">
                        <input onChange={handleChange} name="slug" type="text" value={data.slug} />
                        <h5>Slug</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleChange} name="name" type="text" value={data.name} />
                        <h5>Name</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleBgChange} name="name" type="text" value={dataBg.name} />
                        <h5>Name-Bg</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleChange} name="description" type="text" value={data.description} />
                        <h5>Description</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleBgChange} name="description" type="text" value={dataBg.description} />
                        <h5>Description-Bg</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleChange} name="rank" type="text" value={data.rank} />
                        <h5>Rank</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleBgChange} name="rank" type="text" value={dataBg.rank} />
                        <h5>Rank-Bg</h5>
                      </div>
                      <div className="Item_field">
                        <input onChange={handleChange} name="linkedin" type="text" value={data.linked} />
                        <h5>LinkedIn</h5>
                      </div>
                    </div>
                  </Route>
                  <Route path={`${url}/preview`}>

                  </Route>
                </Switch>
              </div>
              <div className="Item_actions">
                <button onClick={save}><i className="material-icons">create</i>Create</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateTeam;

import React from 'react';
import './PreviewProject.scss';

export default function PreviewProject({ data, dataBg }) {
  return(
    <div className="Projects">
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/projects/"} className="Project">
        <div className="Project_image" style={{ backgroundImage: 'url('+data.image+')' }}></div>
         <h3>{data.title}</h3>
         <p>{data.description}</p>
      </a>
      <a target="_blank" rel="noopener noreferrer" href={"http://localhost/projects/"} className="Project">
        <div className="Project_image" style={{ backgroundImage: 'url('+data.image+')' }}></div>
         <h3>{dataBg.title}</h3>
         <p>{dataBg.description}</p>
      </a>
    </div>
  );
}

import React, { useState, useRef, useCallback } from 'react';
import './Projects.scss';
import { Link } from "react-router-dom";
import useDataSearch from '../../components/useInfiniteScroll/useInfiniteScroll'

function Projects() {
  const [url, setUrl] = useState(`${process.env.REACT_APP_API_URL}/api/projects/`)

  const {
    data,
    hasMore,
    loading,
    next
  } = useDataSearch(url)

  const observer = useRef()
  const lastDataElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setUrl(next)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore, next])

  return (
    <div className="Route_projects">
      <section className="Section">
          <div className="Section_head">
            <h1>Projects</h1>
            <div className="Slot_menu">
              <Link to="/create/projects/image"><i className="material-icons">add</i>Create Project</Link>
            </div>
          </div>
          <div className="Section_body">
            <div className="Shell">
              <div className="Slots">
                {data.map((project, index) => {
                  if (index + 1 === 20) {
                  return <Link ref={lastDataElementRef} to={"/edit/projects/" + project.slug + "/image/"} key={project.slug} className="Slot">
                    {project.video === "" ?
                    <div className="Slot_image">
                      <img src={project.image} alt="item" />
                    </div> :
                    <div className="Slot_video">
                      <video muted autoPlay loop playsInline>
                        <source src={project.video} type="video/mp4" />
                      </video>
                    </div>}
                    <div className="Slot_desc">
                      <h5>{project.category}</h5>
                      <h3>{project.title}</h3>
                    </div>
                  </Link>
                  }
                  else {
                    return <Link to={"/edit/projects/" + project.slug + "/image/"} key={project.slug} className="Slot">
                      {project.video === "" ?
                      <div className="Slot_image">
                        <img src={project.image} alt="item" />
                      </div> :
                      <div className="Slot_video">
                        <video muted autoPlay loop playsInline>
                          <source src={project.video} type="video/mp4" />
                        </video>
                      </div>}
                      <div className="Slot_desc">
                        <h5>{project.category}</h5>
                        <h3>{project.title}</h3>
                      </div>
                    </Link>
                  }
                })}
              </div>
            </div>
          </div>
      </section>
    </div>
  );
}

export default Projects;
